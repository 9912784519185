.invite-popup {
	position: absolute;
	background-color: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.invite-container {
	width: 700px;
	height: fit-content;
	background-color: var(--gray-300);
	border-radius: 10px;
	display: flex;
	align-content: center;
	justify-content: center;
	flex-direction: column;
	padding: 20px;
}

.lobby-id-container {
	color: white;
	margin-bottom: 10px;
	display: flex;
	font-size: 20px;
}

.lobby-id-title {
	font-weight: 600;
}

.lobby-id {
	margin-left: 10px;
	color: var(--gray-500);
}

.green {
	border: solid 1px rgb(47, 148, 47);
}

.invite-link {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--gray-100);
	padding: 5px;
	border-radius: 5px;
	width: 100%;

	.link-icon {
		color: white;
		margin-inline: 5px;
		color: var(--gray-400);
	}

	input {
		background-color: transparent;
		border: none;
		outline: none;
		margin-right: 5px;
		width: 100%;
		color: var(--gray-600);
	}

	button {
		color: var(--gray-600);
		outline: none;
		border-radius: 3px;
		border: none;
		padding: 5px 10px;
		background-color: var(--gray-300);
		cursor: pointer;
	}
}

@media (max-width: 850px) {
	.invite-popup {
		position: static;
		background-color: transparent;
	}
	.invite-container {
		padding: 15px;
		margin: 0;
	}

	.lobby-id-container {
		font-size: 16px;
	}
}
