.popup-container {
	border-radius: 25px;
	background-color: var(--gray-300);
	color: white;
	width: 500px;
	height: 320px;
	display: flex;
	padding-top: 35px;
	align-items: center;
	flex-direction: column;
	position: relative;
}

.warning-icon-container {
	display: flex;
	justify-content: center;
	align-items: center;
	.warning-icon {
		width: 80px !important;
		height: 80px;
		color: rgb(221, 60, 60);
		z-index: 1;
	}

	.exclamation-color {
		width: 20px;
		height: 55px;
		border-radius: 25px;
		background-color: white;
		position: absolute;
	}
}

.album-warning-text {
	text-align: center;
	margin-top: 10px;
	margin-bottom: 25px;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.album-warning-buttons {
	button {
		width: 150px;
		height: 40px;
		border-radius: 50px;
		outline: none;
		border: none;
		margin-inline: 10px;
		cursor: pointer;
	}
}
