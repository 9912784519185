.time-bar-container {
	margin-top: 5px;
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.time {
	font-size: 14px;
}

.time-bar {
	width: 100%;
	height: 4px;

	margin-inline: 10px;
	background-color: var(--gray-400);
	position: relative;

	.time-bar-slider {
		height: 10px;
		width: 3px;
		background-color: var(--gray-600);
		position: absolute;
		top: -3px;
	}
}
