.search-form {
	display: flex;
}

.results {
	width: 100%;
	display: flex;
	overflow-y: auto;
	margin-block: 10px;
}
