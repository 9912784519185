.track-display-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.track-display-top {
  display: flex;
  align-items: center;
}

.track-display-left {
  width: 250px;
  min-width: 250px;
  height: 250px;

  .default-album {
    width: 250px;
    min-width: 250px;
    height: 250px;
    background-color: var(--gray-400);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 150px;
    font-weight: 600;
    color: var(--gray-600);
    position: relative;
  }

  .logo {
    width: 60%;
    height: 60%;
    position: absolute;
  }

  .track-cover {
    width: auto;
    height: auto;
    object-fit: cover;
  }
}

.track-display-right {
  margin-left: 40px;
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 155px;
  overflow: hidden;

  .track-title-container {
    display: flex;
    width: fit-content;
  }

  .track-title {
    font-weight: 400;
    font-size: 80px;
    white-space: nowrap;
    font-weight: 700;
    line-height: 90px;
    width: fit-content;
    padding-right: 80px;
  }

  .artists-container {
    display: flex;
    width: fit-content;
  }

  .artists {
    font-size: 30px;
    color: var(--gray-600);
    white-space: nowrap;
    width: fit-content;
    padding-right: 50px;
  }

  .time-bar-container {
    margin-top: 10px;
    justify-content: flex-start;
    width: 100%;
    display: none;
  }
}

.slide {
  animation: slide 15s linear 0s infinite;
}

.queue-header-wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 20px auto 0 auto;
  display: flex;
  width: 100%;
  padding-bottom: 8px;
}

.queue-header {
  width: 100%;
  display: flex;
  border-bottom: var(--gray-400) 1px solid;
  padding-bottom: 5px;
}

.header-text {
  text-transform: uppercase;
  font-size: 14px;
  color: var(--gray-600);
}

.track-index {
  min-width: 53px;
  text-align: center;
}

.track-title {
  min-width: 40%;
}

.track-user {
  min-width: 20%;
}

.track-duration {
  width: 17%;
  min-width: 17%;
}

.track-like {
  width: 11%;
  min-width: 42px;
}

.track-remove {
  min-width: 55px;
}

@media (max-width: 1300px) {
  .track-title {
    width: 45%;
  }
  .track-duration {
    width: 30%;
  }
  .track-user {
    display: none;
  }

  .track-display-wrapper {
    height: fit-content;
  }

  .track-display-top {
    height: fit-content;
  }

  .track-display-left {
    width: 200px;
    height: 200px;
    min-width: 200px;

    .default-album {
      width: 200px;
      min-width: 200px;
      height: 200px;
    }
  }

  .track-display-right {
    .track-title {
      font-size: 65px;
    }

    .artists {
      font-size: 20px;
    }
  }
}

@media (max-width: 1000px) {
  .track-display-left {
    width: 100%;
    height: fit-content;

    .default-album {
      width: auto;
      min-width: auto;
      padding-bottom: 50%;
      padding-top: 50%;
    }
  }

  .track-display-right {
    height: fit-content;
    margin-block: 20px;
  }

  .track-display-top {
    flex-direction: column;
  }

  .queue-header-wrapper {
    overflow-y: auto;
    width: 100%;
  }

  .track-display-right {
    margin-left: 0px;
  }
}

@media (max-width: 950px) {
  .track-title {
    width: 100%;
  }
  .track-duration {
    display: none;
  }

  .track-like {
    display: none;
  }
}

@media (max-width: 850px) {
  .track-display-left {
    width: 250px;
    height: 250px;
    min-width: 250px;
  }

  .track-display-right {
    margin-left: 20px;
    .track-title {
      font-size: 80px;
    }

    .artists {
      font-size: 30px;
    }

    .time-bar-container {
      display: flex;
    }
  }

  .track-display-top {
    flex-direction: row;
  }
  .track-display-right {
    margin-left: 40px;
  }

  .track-title {
    min-width: 45%;
    width: 45%;
  }

  .track-duration {
    display: block;
    width: 25%;
    min-width: 25%;
  }

  .track-like {
    display: block;
    width: 11%;
    min-width: 42px;
  }

  .track-remove {
    min-width: 55px;
  }
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@media (max-width: 600px) {
  .track-display-left {
    width: 200px;
    height: 200px;
    min-width: 200px;
  }

  .track-display-right {
    .track-title {
      font-size: 65px;
    }

    .artists {
      font-size: 20px;
    }

    .time-bar {
      margin-inline: 10px;
    }

    .time-bar-container p {
      margin: 0;
    }
  }

  .queue-header .track-title {
    width: 100%;
  }

  .track-duration {
    display: none;
  }
}

@media (max-width: 450px) {
  .track-display-top {
    flex-direction: column;

    .track-display-left {
      width: 100%;
      height: fit-content;
      min-width: auto;
      margin-left: 0px;

      .default-album {
      }

      .track-cover {
        width: auto;
        height: auto;
      }
    }

    .track-display-right {
      margin-left: 0px;
      height: fit-content;
      margin-top: 10px;

      .track-title {
        font-size: 25px;
        font-weight: 500;
        line-height: normal;
      }

      .artists {
        font-size: 16px;
        font-weight: 300;
      }
    }
  }

  .track-like {
    display: none;
  }
}
