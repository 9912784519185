.search-results-button {
	height: 20px;
	width: 3%;
}
.temp-ui {
	display: flex;
}
.temp-ui > * {
	flex-basis: 100%;
}
.skip-icon {
	margin-inline: 10px;
	color: white;
	cursor: pointer;
}
