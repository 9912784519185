.search-albums-display {
	width: 50%;
	padding-left: 10px;
	padding-right: 20px;

	.simple-text {
		color: var(--gray-500);
		width: fit-content;
		cursor: pointer;
	}

	.album-name {
		cursor: pointer;
		pointer-events: auto;
		width: fit-content;
	}
}

@media (max-width: 1000px) {
	.search-albums-display {
		width: 100%;
		padding-right: 10px;
	}
}
