.search-tracks-display {
	width: 50%;
	padding-left: 20px;
	padding-right: 10px;

	.simple-text {
		color: var(--gray-500);
		cursor: pointer;
	}
}

.results-display {
	width: 100%;
	padding: 10px;
	display: flex;
	border-radius: 5px;

	&:hover {
		background-color: rgba(255, 255, 255, 0.1);
	}

	.album-cover-container {
		width: 50px;
		min-width: 50px;
		height: 50px;
		pointer-events: none;
		user-select: none;

		img {
			height: 100%;
			object-fit: cover;
		}
	}

	.text {
		margin-left: 20px;
		width: 100%;
		overflow: hidden;
		pointer-events: none;

		.title {
			font-weight: bold;
			white-space: nowrap;
		}

		.all-artists {
			display: flex;
			width: fit-content;
		}

		.artists {
			pointer-events: auto;
			color: var(--gray-600);
			white-space: nowrap;

			&::after {
				content: ',';
				margin-right: 5px;
			}

			&:last-child::after {
				content: '';
				background-color: red;
			}
		}
	}

	.search-result-action-icon {
		width: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		align-self: flex-end;
	}

	.add-button {
		user-select: none;
		font-size: 30px;
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		color: var(--gray-500);

		&:hover {
			color: var(--gray-600);
		}
	}

	.loading {
		font-size: 15px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: auto;
		user-select: none;
	}

	.check-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		color: rgb(87, 211, 87);
		user-select: none;
	}
}

@media (max-width: 1000px) {
	.search-tracks-display {
		width: 100%;
		padding-left: 10px;
	}
}
