.footer {
	background-color: var(--grey-100);
	height: 500px;
	padding-inline: 40px;
}

.footer-container {
	max-width: 1400px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	margin-inline: auto;
}

.footer-heading {
	color: white;
	font-size: 50px;
}

.footer-button-container {
	display: flex;
}

.footer-top {
	border-bottom: 1px solid var(--grey-400);
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	padding-block: 60px;
}

.footer-text {
	text-align: center;
	max-width: 700px;
	width: 100%;
}

.footer-heading {
	font-size: 45px;
	line-height: 60px;
}

.footer-sub-heading {
	margin-top: 10px;
	color: white;
	font-size: 18px;
}

.button-container {
	margin-top: 40px;
}

.footer-bottom {
	color: white;
	width: 100%;
	display: flex;
	margin-top: 20px;
	justify-content: space-between;
	margin-bottom: 20px;
}

.footer-logo-container {
	width: 120px;
	min-width: 120px;
}

.footer-logo {
	width: 100%;
}

.copyright-text {
	color: var(--grey-500);
	font-size: 14px;
	width: fit-content;
	text-align: center;
}

@media (max-width: 800px) {
	.footer-heading {
		font-size: 40px;
		line-height: 45px;
	}

	.footer-sub-heading {
		font-size: 16px;
		padding-inline: 40px;
	}
}

@media (max-width: 500px) {
	.footer {
		height: fit-content;
	}

	.footer-heading {
		font-size: 35px;
		line-height: 40px;
	}

	.footer-sub-heading {
		font-size: 14px;
		padding-inline: 20px;
		margin-top: 20px;
	}

	.footer-bottom {
		align-items: center;
		flex-direction: column;
	}

	.copyright-text {
		margin-top: 40px;
	}
}
