.lobby-members {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.members-container {
}

.member-display {
	position: relative;
	padding-bottom: 8px;
	font-size: 18px;
	color: var(--gray-500);
	display: flex;
	align-items: center;
	font-size: 16px;
	padding: 5px 10px;
	border-radius: 5px;
	cursor: pointer;
	margin-bottom: 5px;

	&:hover {
		background-color: var(--gray-200);
		.tool-tip {
			display: block;
		}
	}
}

.tool-tip {
	display: none;
	background-color: var(--gray-300);
	position: absolute;
	border-radius: 5px;
	padding: 4px 6px;
	top: -40px;
	font-size: 14px;
	right: 5%;
}

.triangle {
	width: 10px;
	height: 10px;
	position: absolute;
	bottom: -5px;
	left: 10px;
	transform: rotate(45deg);
	background-color: var(--gray-300);
}

.mark-as-me {
	background-color: var(--gray-200);
}

.member-icon {
	width: 16px !important;
	height: 16px;
	border-radius: 50%;
	margin-right: 20px;
}

.crown-icon {
	margin-left: auto;
	height: 14px;
	color: rgb(218, 159, 33);
}

.lobby-logo {
	height: 40px;
}

@media (max-width: 850px) {
	.lobby-members {
		padding: 20px 30px;
	}

	.member-display {
		padding-bottom: 12px;
		font-size: 20px;

		&:hover {
			background-color: var(--gray-300);
		}
	}

	.mark-as-me {
		background-color: var(--gray-300);
	}

	.member-icon {
		width: 20px !important;
		height: 20px;
		border-radius: 50%;
		margin-right: 20px;
	}

	.crown-icon {
		margin-left: auto;
		height: 18px;
		color: rgb(218, 159, 33);
	}
}
