.queue-track {
	padding-block: 7px;
	display: flex;
	align-items: center;
	position: relative;
	border-radius: 5px;
	margin: auto;
	cursor: pointer;

	.text {
		margin-left: 20px;
		pointer-events: none;
	}
}

.queue-track:hover {
	background-color: var(--gray-250);
}

.index-wrapper {
	min-width: 53px;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	user-select: none;
}

.index {
	text-align: center;
}

.primary-info {
	display: flex;
	min-width: 40%;
	white-space: nowrap;
	pointer-events: none;

	.album-cover-container {
		width: 50px;
		min-width: 50px;
		height: 50px;
		user-select: none;

		img {
			height: 100%;
			object-fit: cover;
		}
	}

	.text {
		width: 250px;
		overflow: hidden;
	}

	.track-title-container,
	.artists-title-container {
		display: flex;
		position: relative;
	}

	.three-dots {
		background-color: var(--gray-200);
		position: absolute;
		right: 0;
		padding-inline: 2px;
	}

	.hover {
		background-color: var(--gray-250);
	}

	.track-title {
		color: white;
		width: fit-content;
	}

	.track-artists {
		color: var(--gray-600);
		width: fit-content;
	}

	.availability {
		display: flex;
		align-items: center;
		margin-left: 10px;
		height: 24px;
		margin-right: 10px;

		.apple-logo {
			width: 20px;
			min-width: 20px;
			height: 20px;
		}

		.spotify-logo {
			width: 20px;
			min-width: 20px;
			height: 20px;
		}
	}
}

.added-by {
	pointer-events: none;
	min-width: 20%;
	width: 20%;
	color: var(--gray-600);
	user-select: none;
	font-size: 14px;
}

.duration {
	width: 17%;
	min-width: 17%;
	color: var(--gray-600);
	pointer-events: none;
	user-select: none;
	font-size: 14px;
}

.action-buttons {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 40px;
	display: flex;
	color: var(--gray-600);
}

.action-icon {
	cursor: pointer;
	height: 16px;
	pointer-events: auto;
	color: var(--gray-600);

	&:hover {
		color: var(--gray-800);
	}
}

.like-button {
	width: 11%;
	min-width: 42px;
	pointer-events: none;
}

.remove-button {
	min-width: 55px;
	pointer-events: none;
}

@media (max-width: 1300px) {
	.primary-info {
		width: 45%;
	}
	.duration {
		width: 30%;
	}
	.added-by {
		display: none;
	}
}

@media (max-width: 1000px) {
	.queue-track {
		width: 100%;
	}
}

@media (max-width: 950px) {
	.primary-info {
		width: 100%;
	}
	.duration {
		display: none;
	}

	.like-button {
		display: none;
	}

	.remove-button {
		min-width: 35px;
	}
}

@media (max-width: 850px) {
	.primary-info {
		min-width: 45%;
		width: 45%;
	}

	.duration {
		display: block;
		width: 25%;
		min-width: 25%;
	}

	.like-button {
		display: block;
		width: 11%;
		min-width: 42px;
	}

	.remove-button {
		min-width: 55px;
	}
}

@media (max-width: 600px) {
	.duration {
		display: none;
	}

	.primary-info {
		width: 100%;
	}
}

@media (max-width: 450px) {
	.lobby-queue {
		.track-title {
			font-size: 14px;
		}

		.track-artists {
			font-size: 13px;
		}
	}

	.index-wrapper {
		min-width: 40px !important;
	}
	.like-button {
		display: none;
	}
	.remove-button {
		min-width: 35px;
	}
}
