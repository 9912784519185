.lobby-center-queue-wrapper {
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	padding: 40px 40px 0 40px;
}

@media (max-width: 1000px) {
	.lobby-center-queue-wrapper {
		padding: 30px 30px 0 30px;
		display: block;
		overflow-y: auto;
	}
}

@media (max-width: 450px) {
	.lobby-center-queue-wrapper {
		padding: 20px 20px 0 20px;
	}
}
