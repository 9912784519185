.lds-dual-ring {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	position: absolute;
}
.lds-dual-ring:after {
	content: ' ';
	display: block;
	width: 100px;
	height: 100px;
	margin: 8px;
	border-radius: 50%;
	border: 12px solid #fff;
	border-color: #fff transparent #fff transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
